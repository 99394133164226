import { DomSanitizer } from '@angular/platform-browser';
import { BcfRestApi } from 'core-app/features/bim/bcf/bcf-constants.const';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { imagePath } from 'core-app/shared/helpers/images/path-helper';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import { CollectionResource } from 'core-app/features/hal/resources/collection-resource';
import { ProjectResource } from 'core-app/features/hal/resources/project-resource';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Component, signal, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { SchemaResource } from 'core-app/features/hal/resources/schema-resource';
import { ColorsService } from 'core-app/shared/components/colors/colors.service';
import { HttpClient } from '@angular/common/http';

interface SchemaField {
  name: string;
  type: string;
  // ... properti lain yang relevan
}

export const homescreenProjectCalendarBlockSelector = 'homescreen-project-calendar-block';

@Component({
  template: `
    <div class="op-section-project-calendar">
        <full-calendar #calendar [options]='calendarOptions()'>
          <ng-template #eventContent let-arg>
            <div class="project"
              [ngStyle]="{
                'background-color': getBackgroundEvent(arg.event.title), 
                'color': getFontColorEvent(arg.event.title),
                'border-left': getBorder(arg.event.title)
              }"
            >
              <div class="project-info">
                <p 
                  class="project-info-status"
                  [ngClass]="getStatusCssClass(arg.event.extendedProps.projectStatus)"
                >{{arg.event.extendedProps.projectStatus}}</p>
                <p class="project-info-days">{{arg.event.extendedProps.durationDays}} days</p>
              </div>
              <p class="project-title">{{arg.event.title}}</p>
              <p class="project-start-date">Start: {{arg.event.start | date:'M/d/yyyy'}}</p>
              <p class="project-end-date">End: {{arg.event.end | date:'M/d/yyyy'}}</p>
              <p class="project-project-type">{{arg.event.extendedProps.projectType}}</p>
              <div class="assignees">
                <div class="item-assignee"
                *ngFor="let assignee of arg.event.extendedProps.memberships"
                [style.background]="getColorCode(assignee)"
                [textContent]="getInitials(assignee)"
                >
                  M
                </div>
              </div>
            </div>
          </ng-template>
        </full-calendar>
    </div>
  `,
  selector: homescreenProjectCalendarBlockSelector,
  styleUrls: ['./homescreen-project-calendar.component.sass'],
  encapsulation: ViewEncapsulation.None
})

export class HomescreenProjectCalendarBlockComponent {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  projectSchema: SchemaResource;

  private baseColorA4D5FA: [number, number, number] = [204, 87, 83]

  calendarVisible = signal(true);
  calendarOptions = signal<CalendarOptions>({
    plugins: [
      dayGridPlugin,
    ],
    headerToolbar: {
      left: 'prev title next',
      center: 'dayGridWeek,dayGridMonth',
      right: ''
    },
    initialView: 'dayGridWeek',
    slotLabelFormat: [
      { 
        weekday: 'short' // 'short' untuk format singkat hari, misal 'Sun'
      },
      {
        day: '2-digit' // '2-digit' untuk tanggal dengan dua digit
      }
    ],
    firstDay: 1,
    initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    height: 600
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  });
  currentEvents = signal<EventApi[]>([]);

  constructor(
    private httpClient:HttpClient,
    private changeDetector: ChangeDetectorRef, 
    private apiV3Service: ApiV3Service, 
    private colors:ColorsService ) {
  }

  handleWeekendsToggle() {
    this.calendarOptions.mutate((options) => {
      options.weekends = !options.weekends;
    });
  }

  handleEventClick(clickInfo: EventClickArg) {
    const projectUrl = clickInfo.event.extendedProps.projectUrl; // Asumsikan Anda memiliki URL dalam extendedProps
    if (projectUrl) {
      window.location.href = projectUrl; // Menggunakan Angular Router untuk navigasi
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents.set(events);
    this.changeDetector.detectChanges(); // workaround for pressionChangedAfterItHasBeenCheckedError
  }

    ngOnInit(): void {
    this.loadProjects();
  }
  
  private loadProjects(): void {
    this.apiV3Service.projects.schema.get().subscribe(
      (schema: SchemaResource) => {
        this.projectSchema = schema;
        let startDateField: string | undefined;
        let endDateField: string | undefined;
        let projectTypeField: string | undefined;
        
        for (const [key, value] of Object.entries(schema.$source)) {
          const field = value as SchemaField; // Type assertion
  
          if (field.name === 'Start Date') {
            startDateField = key;
          } else if (field.name === 'End Date') {
            endDateField = key;
          } else if (field.name === 'Project Type') {
            projectTypeField = key
          }
        }
  
        if (startDateField && endDateField) {
          this.apiV3Service.projects.list().subscribe(
            (projectCollection) => {
              const calendarApi = this.calendarComponent.getApi();
      
              projectCollection.elements.forEach((project) => {
                const startDate = new Date(project[startDateField as keyof typeof project]);
                const endDate = new Date(project[endDateField as keyof typeof project]);

                const timeDiff = endDate.getTime() - startDate.getTime();
                const dayDiff = timeDiff / (1000 * 3600 * 24);

                if (project.memberships && project.memberships.href && startDate && endDate && project.status.name != "Closed") {
                  // Lakukan HTTP GET request ke URL memberships
                  this.httpClient.get(project.memberships.href).subscribe((membershipsResponse: any) => {
                    const memberships = membershipsResponse._embedded.elements;
                    console.log(project[projectTypeField as keyof typeof project])
                    const projectTypeValue = project[projectTypeField as keyof typeof project]
                    // Buat sebuah array untuk menampung semua nama anggota
                    const memberNames = memberships.map((membership: any) => membership._links.principal.title);
                    const event: EventInput = {
                      title: project.name,
                      start: startDate,
                      end: endDate,
                      extendedProps: {
                        projectType: projectTypeField == undefined || projectTypeValue == undefined ? '-' : project[projectTypeField as keyof typeof project].$source._links.self.title,
                        durationDays: dayDiff + 1,
                        projectStatus: project.status.name,
                        memberships: memberNames,
                        projectUrl: `/projects/${project.identifier}`
                      }
                    };
                    console.log(this.getColorCode('Project admin DEV user'))
                    // Tambahkan event ke kalender
                    this.calendarComponent.getApi().addEvent(event);
                  });
                }
              });
            },
            (error) => {
              console.error('Error loading projects', error);
            }
          );
        } else {
          console.error('Start Date or End Date fields not found in project schema');
        }
      },
      (error: any) => {
        console.error('There was an error fetching the project schema', error);
      }
    );
  }

  getInitials(name:string):string {
    const characters = [...name];
    const lastSpace = name.lastIndexOf(' ');
    const first = characters[0]?.toUpperCase();
    const last = name[lastSpace + 1]?.toUpperCase();

    return [first, last].join('');
  }

  getColorCode(name:string): string {
    return this.colors.toHsl(name)
  }

  getBackgroundEvent(projectName:string): string {
    return this.colors.generateProjectColor(this.baseColorA4D5FA, projectName, 0.5)
  }

  getFontColorEvent(projectName:string): string {
    return this.colors.generateFontColor(this.baseColorA4D5FA, projectName, 50)
  }

  getStatusCssClass(status: string): string {
    switch (status) {
      case 'New':
        return 'status-new';
      case 'Closed':
        return 'status-closed';
      case 'In Progress':
        return 'status-in-progress';
      case 'Completed':
        return 'status-completed';
      case 'On-hold':
        return 'status-on-hold';
      default:
        return 'status-unknown';
    }
  }

  getBorder(projectName: string): string {
    const borderColor = this.colors.generateFontColor(this.baseColorA4D5FA, projectName, 40);;
    return `5px solid ${borderColor}`;
  }
}