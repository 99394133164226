import { Injectable } from '@angular/core';

export const colorModes = {
  lightHighContrast: 'lightHighContrast',
  light: 'light',
};

@Injectable({ providedIn: 'root' })
export class ColorsService {
  public toHsl(value:string, colorMode?:string):string {
    return `hsl(${this.valueHash(value)}, 50%, ${colorMode === colorModes.lightHighContrast ? '30%' : '50%'})`;
  }

  public toHsla(value:string, opacity:number) {
    return `hsla(${this.valueHash(value)}, 50%, 30%, ${opacity}%)`;
  }

  // protected valueHash(value:string) {
  //   let hash = 0;
  //   for (let i = 0; i < value.length; i++) {
  //     hash = value.charCodeAt(i) + ((hash << 5) - hash);
  //     console.log(hash)
  //   }
  //   console.log(hash % 360)
  //   return hash % 360;
  // }

  protected valueHash(value: string) {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      hash = (value.charCodeAt(i) + ((hash << 5) - hash)) >>> 0; // Convert to 32-bit unsigned integer
    }
    return hash % 360;
  }

  public colorMode():string {
    return document.body.getAttribute('data-light-theme') === 'light_high_contrast' ? colorModes.lightHighContrast : colorModes.light;
  }

  public generateProjectColor(baseColorHSL: [number, number, number], inputString: string, opacity: number = 1): string {
    const [baseH, baseS, baseL] = baseColorHSL;
    const hashValue = this.valueHash(inputString);
    const newH = (baseH + hashValue) % 360;
    return `hsla(${newH}, ${baseS}%, ${baseL}%, ${opacity})`;
  }

  public generateFontColor(baseColorHSL: [number, number, number], inputString: string, darkerBy: number = 30): string {
    const [baseH, baseS, baseL] = baseColorHSL;
    const hashValue = this.valueHash(inputString);
    const newH = (baseH + hashValue) % 360;
    const newL = Math.max(baseL - darkerBy, 0); // Pastikan tidak kurang dari 0
    return `hsla(${newH}, ${baseS}%, ${newL}%, 1)`;
  }
}
